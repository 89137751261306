import React, { Component } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import axios from '../axios'

class ManualOperation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      buttons: [
        { level: 1, percentage: '20%' },
        { level: 2, percentage: '40%' },
        { level: 3, percentage: '60%' },
        { level: 4, percentage: '80%' },
        { level: 5, percentage: '100%' },
      ],
      activeIndex: 0,
      activeLevel: this.getManualOperationValue,
    }
  }

  getActiveIndex = status => {
    this.setState({
      activeIndex: status - 1,
    })
    console.log(this.state.activeIndex)
  }

  getManualOperationValue() {
    axios
      .get('/get/manual_operation1', {})
      .then(res => {
        this.getActiveIndex(res.data.level)
        console.log(res.data.level)
        return res.data.level
      })
      .catch(e => {
        console.error(e)
      })
  }

  sendLevel = level => {
    const operationLevel = level
    axios
      .post('/post/manual_operation', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          level: operationLevel,
        },
      })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getManualOperationValue()
  }

  handleClick = (i, level) => {
    this.setState({ activeIndex: i })
    this.sendLevel(level)
    console.log('i = ' + i)
    console.log('level = ' + level)
  }
  render() {
    const { buttons, activeIndex, activeLevel } = this.state
    return (
      <div>
        {buttons.map((btn, i) => (
          <div className='interfaceRow justifyCenter' key={btn.level}>
            <button
              className={
                i === activeIndex || activeLevel === btn.level ? 'active' : ''
              }
              onClick={() => this.handleClick(i, btn.level)}
            >
              {' '}
              <FormattedMessage id='pages.operation.level' /> {btn.level} -{' '}
              {btn.percentage}
            </button>
          </div>
        ))}
      </div>
    )
  }
}

export default ManualOperation
