import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'
import ManualOperation from '../components/operation/manualOperation'

const ManualOperationPage = ({ intl }) => (
  <>
    <SEO
      title={intl.formatMessage({ id: 'pages.operation.manualoperation' })}
    />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <ManualOperation />
    </div>
  </>
)
export default injectIntl(ManualOperationPage)
